import React from "react"
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import Seo from "../utils/seo"
import Stars4_1 from "/static/star_ratings/summit_stars_4_1.svg";
import Stars4_2 from "/static/star_ratings/summit_stars_4_2.svg";
import Stars4_3 from "/static/star_ratings/summit_stars_4_3.svg";
import Stars4_4 from "/static/star_ratings/summit_stars_4_4.svg";
import Stars4_5 from "/static/star_ratings/summit_stars_4_5.svg";
import Stars4_6 from "/static/star_ratings/summit_stars_4_6.svg";
import Stars4_7 from "/static/star_ratings/summit_stars_4_7.svg";
import Stars4_8 from "/static/star_ratings/summit_stars_4_8.svg";
import Stars4_9 from "/static/star_ratings/summit_stars_4_9.svg";
import Stars5 from "/static/star_ratings/summit_stars_5.svg";

const getStarsImage = (starRating) => {
  switch (starRating) {
    case "4.1": return Stars4_1;
    case "4.2": return Stars4_2;
    case "4.3": return Stars4_3;
    case "4.4": return Stars4_4;
    case "4.5": return Stars4_5;
    case "4.6": return Stars4_6;
    case "4.7": return Stars4_7;
    case "4.8": return Stars4_8;
    case "4.9": return Stars4_9;
    case "5": return Stars5;
    default: return null;
  }
};

const Page = ({ blok,firstPublished,publishedAt  }) => {
  const firstBlock = blok?.body?.[0]?._uid || null;
  
  const content = blok.body?.map(childBlok => 
    <DynamicComponent blok={childBlok} key={childBlok._uid} pageComponent={blok?.component} firstBlock={firstBlock}/>
  );

  const isPost = blok?.component === "post"; // Check if it's a blog post

  // Define the article schema in the specified format
  const articleSchema = blok.component === 'post' ? {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "headline": blok?.body?.[0]?.title || blok.meta?.title,
    "datePublished": firstPublished,
    "dateModified": publishedAt,
    "author": [{
      "@type": "Organization",
      "name": "Summit Training",
      "url": "https://www.summittraining.co.uk/"
    }]
  } : null; // If not a post, set to null

  // Organization Schema (always present)
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "image": "https://www.example.com/example_image.jpg",
    "url": "https://www.summittraining.co.uk/",
    "logo": "https://www.example.com/images/logo.png",
    "name": "Summit Training",
    "description": "Tailored Management Training courses and Leadership Development courses designed by experts to deliver great results for your organisation.",
    "email": "info@summittraining.co.uk",
    "telephone": "0845 052 3701",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "33 Harrison Road",
      "addressLocality": "Halifax",
      "addressCountry": "UK",
      "addressRegion": "west-yorkshire",
      "postalCode": "HX1 2AF"
    }
  };

  // Combine schemas into an array
  const combinedSchema = isPost ? [articleSchema] : [organizationSchema];

  return (
    <SbEditable content={blok} key={blok._uid}>
      <Seo 
        title={blok.meta?.title} 
        description={blok.meta?.description} 
        image={blok.meta?.og_image} 
        schema={combinedSchema} // ✅ Pass schemas as an array
      />
      {blok.star_rating && (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4">
          <div className="mt-24 py-6">
            <img className="h-[36px] mb-3" src={getStarsImage(blok.star_rating)} alt="Star Rating" />
            <div className="w-full ml-2 uppercase font-body">
              <p><span className="font-semibold">{blok.star_rating} stars</span> - based on {blok.participant_number} participants</p>
            </div>
          </div>
        </div>
      )}
      {content}
    </SbEditable>
  );
};

export default Page;
