import React from 'react'
import SbEditable from 'storyblok-react'
import RichText from 'src/utils/RichText'
import Label from 'src/components/Label'
import Heading from 'src/components/Heading'
import { BackgroundColour, TitleColour, ButtonColour, LinkColour } from 'src/utils/ColourUtilities'
import { useCookies } from "react-cookie"

const SplitTwoTone = ({blok}) => {
    const [cookies] = useCookies()
    const { gclid } = cookies
    const { _uetmsclkid } = cookies

  return (
    <SbEditable content={blok} key={blok._uid}>
    <div className={`relative ${BackgroundColour(blok.colour)}`}>
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="pt-16 pb-0 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:pt-24 lg:pb-24 xl:pr-12">
          <div className="max-w-7xl mx-auto font-body">
            <Label className="mb-2">{blok.label}</Label>
            <Heading size="h1" as={blok.title_h_tag ? blok.title_h_tag : 'h2'} className={`${TitleColour(blok.colour)}`}>{blok.title}</Heading>
            <div className={`prose leading-6`}>
              <RichText>{blok.description}</RichText>
            </div>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>Summit Consulting and Training Ltd</p>
                  <p>33 Harrison Road, Halifax</p>
                  <p>HX1 2AF</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <a href="tel:0845 052 3701" className={`duration-300 font-semibold ${LinkColour(blok.colour)}`}>T: 0845 052 3701</a>
                </dd>
              </div>
              <div className="mt-1">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <a href="mailto:info@summittraining.co.uk" className={`duration-300 font-semibold ${LinkColour(blok.colour)}`}>E: info@summittraining.co.uk</a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="pb-16 pt-8 px-4 sm:px-6 lg:col-span-3 lg:pb-24 lg:pt-24 lg:px-8 xl:pl-12">
          <div className="max-w-7xl mx-auto lg:max-w-none">
            <form action="/thank-you/" method="POST" className="grid grid-cols-1 gap-y-6" form netlify-honeypot="bot-field" data-netlify="true" name="contact" netlify>
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <div>
                <label htmlFor="full-name" className="sr-only">
                  My full name
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="My full name*"
                  required
                />
              </div>
              <div>
                <label htmlFor="organization" className="sr-only">
                  My organisation
                </label>
                <input
                  type="text"
                  name="organisation"
                  id="organisation"
                  autoComplete="organization"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="My organisation"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  My email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="My email address*"
                  required
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  My contact number
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="My contact number*"
                  required
                />
              </div>
              <div>
                <label htmlFor="course" className="sr-only">
                  What course are you interested in?
                </label>
                <input
                  type="text"
                  name="course"
                  id="course"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="What course are you interested in?"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  I'd like to discuss...
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold"
                  placeholder="I'd like to discuss...*"
                  defaultValue={''}
                  required
                />
              </div>
              <div>
                <input 
                  type="hidden" 
                  id="gclid"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold" 
                  label="gclid" 
                  value={gclid} 
                  name="gclid" />
              </div>
              <div>
                <input 
                  type="hidden" 
                  id="msclkid"
                  className="font-body block w-full py-3 px-4 placeholder-gray text-pink border-0 !ring-0 border-white font-bold" 
                  label="msclkid" 
                  value={_uetmsclkid} 
                  name="msclkid" />
              </div>
              <div>
                <button
                  type="submit"
                  className={`font-display font-black text-lg leading-6 uppercase italic cursor-pointer inline-block px-6 py-3 line-crop tracking-[0.03em] transition duration-300 ${ButtonColour(blok.colour)}`}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </SbEditable>
  )
};

export default SplitTwoTone;
